<template>
	<form>
		<div>
			<div>
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Upload New Assets
				</h3>
				<p class="mt-1 text-sm leading-5 text-gray-500">
					Upload Content from a CSV directly into asset group, it will not update any matching items
				</p>
			</div>
			<div class="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
				<div class="sm:col-span-3">
					<label for="asset" class="block text-sm font-medium leading-5 text-gray-700">
						Asset Type
					</label>
					<div class="mt-1 rounded-md shadow-sm">
						<select
							id="asset"
							v-model="option"
							class="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						>
							<option v-for="o in options" :key="o.name" :value="o">{{ o.name }}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-8 border-t border-gray-200 pt-8">
			<div>
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Upload Content
				</h3>
				<p v-if="option && Object.values(option.fields)" class="mt-1 text-sm leading-5 text-gray-500">
					The CSV should have the following columns:
					<span class=" font-bold font-mono">{{ Object.values(option.fields) | arrayToSentence }}</span>
				</p>
			</div>
			<div v-if="option" class="mt-6 ">
				<label for="importer" class="block text-sm leading-5 font-medium text-gray-700">
					CSV File
				</label>
				<div class="mt-2 px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
					<csv-importer
						id="importer"
						v-model="parseCsv"
						:map-fields="option.fields"
						:has-headers="hasHeaders"
						button-class="btn btn-indigo"
						input-class="form-input"
					></csv-importer>
				</div>
			</div>
			<div v-if="parseCsv.length" class="mt-6">
				<div>
					<label for="hasHeaders" class="text-sm leading-5 font-medium text-gray-700">
						Has Headers?
					</label>
					<span
						id="hasHeaders"
						:class="{ 'bg-gray-200': !hasHeaders, 'bg-indigo-600': hasHeaders }"
						class="relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
						role="checkbox"
						tabindex="0"
						:aria-checked="hasHeaders.toString()"
						@click="hasHeaders = !hasHeaders"
						@keydown.space.prevent="hasHeaders = !hasHeaders"
					>
						<span
							aria-hidden="true"
							:class="{ 'translate-x-5': hasHeaders, 'translate-x-0': !hasHeaders }"
							class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
						></span>
					</span>
				</div>
				<div class="mt-2 px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
					<table v-if="option" class="min-w-full">
						<th v-for="o in Object.keys(option.fields)" :key="o" class="thead">{{ o }}</th>
						<tbody>
							<tr v-for="(p, i) in parseCsv" :key="i">
								<td v-for="o in Object.keys(option.fields)" :key="o" class="td">{{ p[o] }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div v-if="parseCsv.length" class="mt-8 border-t border-gray-200 pt-5">
			<div class="flex justify-end">
				<span class="inline-flex rounded-md shadow-sm">
					<button type="button" class="btn btn-cancel">
						Cancel
					</button>
				</span>
				<span class="ml-3 inline-flex rounded-md shadow-sm">
					<button type="submit" class="btn btn-indigo" @click.prevent="importData">
						Save
					</button>
				</span>
			</div>
		</div>
	</form>
</template>

<script>
import CsvImporter from '@/components/settings/CsvImporter'
import firebase from '@/helpers/firebaseInit.js'

export default {
	name: 'Upload',
	components: { CsvImporter },
	data() {
		return {
			parseCsv: [],
			hasHeaders: false,
			options: [
				{
					name: 'Creators',
					fields: { name: 'Name', description: 'Description' },
					plural: 'creators',
					collection: firebase.creatorCollection
				},
				{
					name: 'Channels',
					fields: { name: 'Name', description: 'Description' },
					plural: 'channels',
					collection: firebase.channelCollection
				},
				{
					name: 'Entities',
					fields: { name: 'Name', description: 'Description' },
					plural: 'entities',
					collection: firebase.entityCollection
				},
				{
					name: 'Fields',
					fields: {
						name: 'Name',
						description: 'Description',
						dataType: 'Data Type',
						isRequired: 'Is Required',
						gdprStatus: 'GDPR Status',
						gdprPurpose: 'GDPR Purpose',
						retentionPeriod: 'Retention Period',
						retentionPeriodScope: 'Retention Period Timeframe'
					},
					plural: 'fields',
					collection: firebase.fieldCollection
				}
			],
			option: {}
		}
	},
	created() {
		this.option = this.options[0]
	},
	methods: {
		importData() {
			var batch = firebase.firestore.batch()
			let collection = this.option.collection

			this.parseCsv.forEach((i) => {
				i._name = this.shortName(i.name)
				i.account = this.$store.state.accounts.current.id
				i.createdBy = this.$store.state.auth.user.uid
				i.created = new Date()

				batch.set(collection.doc(), i, { merge: true })
			})

			batch.commit().then(() => {
				this.$router.push({ name: this.option.plural })
			})
		}
	}
}
</script>
