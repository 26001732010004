<template>
	<div class="vue-csv-uploader flex">
		<input ref="csv" type="file" class="w-1/4 m-4" name="csv" @change.prevent="validFileMimeType" />
		<div v-if="sample" class="w-3/4 m-4">
			<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
				<div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
					<table class="min-w-full">
						<thead>
							<tr>
								<th class="thead">Field</th>
								<th class="thead">CSV Column</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(field, key) in fieldsToMap" :key="key">
								<td class="td">{{ field.label }}</td>
								<td class="td">
									<select v-model.trim="map[field.key]" class="form-control" :name="`csv_uploader_map_${key}`">
										<option v-for="(column, keyI) in firstRow" :key="keyI" :value="keyI">{{ column }}</option>
									</select>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { drop, every, forEach, get, isArray, map, set } from 'lodash'
import Papa from 'papaparse'
import mimeTypes from 'mime-types'

export default {
	name: 'CsvImporter',
	props: {
		value: { type: Array, default: () => [] },
		mapFields: {
			required: false,
			default: () => ({ name: 'Name', description: 'Description' }),
			type: Object
		},
		callback: {
			type: Function,
			default: () => ({})
		},
		catch: {
			type: Function,
			default: () => ({})
		},
		finally: {
			type: Function,
			default: () => ({})
		},
		parseConfig: {
			type: Object,
			default() {
				return {}
			}
		},
		loadBtnText: {
			type: String,
			default: 'Next'
		},
		submitBtnText: {
			type: String,
			default: 'Submit'
		},
		tableClass: {
			type: String,
			default: 'table'
		},
		checkboxClass: {
			type: String,
			default: 'form-check-input'
		},
		buttonClass: {
			type: String,
			default: 'btn btn-primary'
		},
		inputClass: {
			type: String,
			default: 'form-control-file'
		},
		hasHeaders: {
			type: Boolean,
			default: true
		},
		validation: {
			type: Boolean,
			default: true
		},
		fileMimeTypes: {
			type: Array,
			default: () => {
				return ['text/csv', 'text/x-csv', 'application/vnd.ms-excel', 'text/plain']
			}
		}
	},

	data: () => ({
		form: {
			csv: null
		},
		fieldsToMap: [],
		map: {},
		csv: null,
		sample: null,
		isValidFileMimeType: false,
		fileSelected: false
	}),
	computed: {
		firstRow() {
			return get(this, 'sample.0')
		},
		showErrorMessage() {
			return this.fileSelected && !this.isValidFileMimeType
		},
		disabledNextButton() {
			return !this.isValidFileMimeType
		}
	},
	watch: {
		mapFields: {
			handler() {
				this.map = {}
				this.getFieldsToMap()
			},
			deep: true
		},
		hasHeaders() {
			this.submit()
		},
		map: {
			deep: true,
			handler: function(newVal) {
				if (!this.url) {
					let hasAllKeys = Array.isArray(this.mapFields)
						? every(this.mapFields, function(item) {
								return Object.prototype.hasOwnProperty.call(newVal, item)
						  })
						: every(this.mapFields, function(item, key) {
								return Object.prototype.hasOwnProperty.call(newVal, key)
						  })

					if (hasAllKeys) {
						this.submit()
					}
				}
			}
		}
	},

	created() {
		this.getFieldsToMap()
	},

	methods: {
		getFieldsToMap() {
			if (isArray(this.mapFields)) {
				this.fieldsToMap = map(this.mapFields, (item) => {
					return {
						key: item,
						label: item
					}
				})
			} else {
				this.fieldsToMap = map(this.mapFields, (label, key) => {
					return {
						key: key,
						label: label
					}
				})
			}
		},
		submit() {
			const _this = this
			this.form.csv = this.buildMappedCsv()
			this.$emit('input', this.form.csv)

			_this.callback(this.form.csv)
		},
		buildMappedCsv() {
			const _this = this

			let csv = this.hasHeaders ? drop(this.csv) : this.csv

			return map(csv, (row) => {
				let newRow = {}

				forEach(_this.map, (column, field) => {
					set(newRow, field, get(row, column))
				})

				return newRow
			})
		},
		validFileMimeType() {
			let file = this.$refs.csv.files[0]
			const mimeType = file.type === '' ? mimeTypes.lookup(file.name) : file.type

			if (file) {
				this.fileSelected = true
				this.isValidFileMimeType = this.validation ? this.validateMimeType(mimeType) : true
				this.load()
			} else {
				this.isValidFileMimeType = !this.validation
				this.fileSelected = false
			}
		},
		validateMimeType(type) {
			return this.fileMimeTypes.indexOf(type) > -1
		},
		load() {
			const _this = this

			this.readFile((output) => {
				_this.sample = get(Papa.parse(output, { preview: 2, skipEmptyLines: true }), 'data')
				_this.csv = get(Papa.parse(output, { skipEmptyLines: true }), 'data')
			})
		},
		readFile(callback) {
			let file = this.$refs.csv.files[0]

			if (file) {
				let reader = new FileReader()
				reader.readAsText(file, 'UTF-8')
				reader.onload = function(evt) {
					callback(evt.target.result)
				}
				reader.onerror = function() {}
			}
		},
		makeId(id) {
			return `${id}${this._uid}`
		}
	}
}
</script>
